
import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from 'gatsby'
const News = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <p>
        <h3>News & Press</h3>
        <ul>
        <li style={{listStyleType: "none" }}><em>Ignorance is Not Bliss - Teens In Crisis Want Answers</em>. Yehudah Fine.<br/><Link to={`/2018-12-10-ignorance-bliss`}>Guest Post on The Zero: The Official Website of Alexander Vachs</Link><br/><strong>December 10th, 2018.</strong> </li>
          <hr/>
          <li style={{listStyleType: "none" }}><em>Reaching out and bringing kids back from the street.</em> Nicole Dunsdon.<br/><Link to={`/2005-04-02-calgary`}>Calgary Herald.</Link><br/><strong>2005</strong>.</li>
          <hr/>
          <li style={{listStyleType: "none" }}><em>Been Down So Long It Looks Like Up To Me</em>. Yehudah Fine.<br/><Link to={`/2005-03-14-been-down-so-long`}>The Journal For Living, Issue 25: Crisis As Opportunity</Link>.<br/> </li>
          <hr/>
        <li style={{listStyleType: "none" }}><em>Movie Rights Purchased for Hazelden's Bestseller:Times Square Rabbi</em>.<br/><Link to={`/2003-03-01-movie-rights`}>Press Release</Link><br/><strong>estimated March 2003.</strong> </li>
        <hr/>
          <li style={{listStyleType: "none" }}><em>Commentary: Two Sets of Home Plates</em>. Yehudah Fine.<br/><Link to={`/2003-04-03-two-sets`}>Hadassah Magazine Vol. 84 No. 8</Link><br/><strong>April 3rd, 2003.</strong> </li> 
          <hr/>
          <li style={{listStyleType: "none" }}><em>Family Matters: Country Road, Take Me Home</em>. Yehudah Fine.<br/><Link to={`/2001-05-01-country-road`}>Hadassah Magazine Vol. 82 No. 9</Link><br/><strong> May 1st, 2001.</strong> </li>
          <hr/>
          <li style={{listStyleType: "none" }}><em>Times Square Rabbi: Finding Hope in Lost Kids' Lives.</em>. Yehudah Fine.<br/><Link to={`#`}>National Baseball Hall of Fame.</Link><br/>His book was added to the archives at the National Baseball Hall of Fame in Cooperstown, NY due to its numerous references and lengthy discussion of baseball.</li>
          <hr/>
          <li style={{listStyleType: "none" }}><em>A Streetwise Rabbi's Message Connects with Lost Teens</em>. Barbara Cooke.<br/><Link to={`/1999-09-12-chicago-tribune`}>Chicago Tribune.</Link><br/><strong>September 12, 1999</strong></li>
        </ul>
</p>
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`